import { FunctionComponent } from 'react';

import Button from 'components/layout/Button';
import { ButtonStyles } from 'components/layout/Button/types';

import { Props } from './types';

const ContentSectionsLearnMore: FunctionComponent<Props> = ({ title, description, buttonContent }) => {
    return (
        <div className="learn-more-container">
            <div className="learn-more">
                <div className="learn-more__title">
                    {title}
                </div>
                <div className="learn-more__description">
                    {description}
                </div>
            </div>
        </div>
    );
};

export default ContentSectionsLearnMore;

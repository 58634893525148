import { FunctionComponent } from 'react';

import Details from 'components/content/sections/details';
import Header from 'components/layout/Header';
import { HeaderStyles, HeaderTextPositions } from 'components/layout/Header/types';

import StyledComponent from './styles';
import { Props } from './types';

const PageHomeSectionDetails: FunctionComponent<Props> = ({ translations }) => {
    return (
        <StyledComponent
            className="page-home-section-details"
            id="details"
        >
            <Header
                className="details-header"
                title={translations?.title}
                description={translations?.description}
                style={HeaderStyles.Secondary}
                textPosition={HeaderTextPositions.Center}
            />
            <Details elements={translations?.elements} />
        </StyledComponent>
    );
};

export default PageHomeSectionDetails;

import { FunctionComponent } from 'react';
import classNames from 'classnames';

import StyledComponent from './styles';
import { Props } from './types';

const LayoutHeader: FunctionComponent<Props> = ({ className, title, subTitle, description, isPrimaryTitle, style, textPosition, children }) => ((
    <StyledComponent className={classNames('layout-header',
        `style-${style}`,
        `text-${textPosition}`,
        className
    )}
    >
        {subTitle && (
            <h3
                className="header-subtitle"
                dangerouslySetInnerHTML={{ __html: subTitle }}
            />
        )}
        {title && isPrimaryTitle ? (
            <h1
                className="header-title header-title-primary"
                dangerouslySetInnerHTML={{ __html: title }}
            />
        ) : (
            <h2
                className="header-title"
                dangerouslySetInnerHTML={{ __html: title }}
            />
        )}
        {description && (
            <p
                className="header-description"
                dangerouslySetInnerHTML={{ __html: description }}
            />
        )}
        {children && (
            <>{ children }</>
        )}
    </StyledComponent>
));

export default LayoutHeader;
const translations = {
    elements: {
        type: 'array',
        arraySchema: {
            title: { type: 'text' },
            description: { type: 'rich text' },
            image: { type: 'image' },
        },
        defaultValue: [{
            title: '1. Przejrzyste zalecenia',
            description: 'REHAB przegląda dane pacjenta, tworząc na ich podstawie program rehabilitacyjny dostosowany do celów terapeutycznych. Użytkownicy mogą dzięki temu skupić się na obszarach aktywności wymagających poprawy i osiągać optymalne wyniki.',
            image: '/images/info-boxes/first-card-image.png',
        }, {
            title: '2. Fizjoterapia w Twoim domu',
            description: 'Aplikacja REHAB proponuje różnorodne ćwiczenia i techniki terapeutyczne, które użytkownicy mogą wykonywać samodzielnie. To rozwiązanie pozwala utrzymać aktywny styl życia i przeciwdziała występowaniu kontuzji czy przeciążeń.',
            image: '/images/info-boxes/second-card-image.png',
        }, {
            title: '3. Kompleksowe wsparcie w powrocie do zdrowia',
            description: 'Za pomocą programów treningowych i technik terapeutycznych REHAB pomaga użytkownikom zwiększyć siłę mięśniową, poprawić zwinność i zwiększyć wytrzymałość organizmu.',
            image: '/images/info-boxes/third-card-image.png',
        }, {
            title: '4. Skuteczne łagodzenie bólu',
            description: 'Aplikacja REHAB oferuje techniki terapeutyczne, które pomagają w procesie łagodzeniu bólu, redukcji obrzęków oraz zwiększania zakresu ruchu. To szczególnie ważne dla osób z przewlekłymi dolegliwościami, urazami czy stanami zapalnymi.',
            image: '/images/info-boxes/fourth-card-image.png',
        }],
    },
};

export default translations;

import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.section`
    position: relative;
    width: 100%;
    min-height: 30em;
    overflow: hidden;

    .explore-services {
        margin: 8em auto 3em auto;
    }

    .explore-services-header {
        max-width: 760px;
        margin: auto;
    }

    .image-slider-wrapper {
        padding: 2em 0;

        .image-wrapper {
            background-color: rgba(255, 255, 255, 0.5);
            height: 300px;
            box-shadow: rgba(149, 157, 165, 0.6) 0 4px 16px;
            border-radius: 4px;
            overflow: hidden;

            &:focus {
                outline: none;
            }

            &:focus-visible {
                outline: none;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;

                &:focus {
                    outline: none;
                }

                &:focus-visible {
                    outline: none;
                }
            }
        }
    }

    .more-button {
        margin-top: 1em;
    }

    @media all and (max-width: ${vars.mobileL}) {
        .explore-services {
            margin: 4em auto 2em auto;

            .explore-services-header {
                width: 80%;
                margin: 0 auto;
            }
        }
    }
`;

import { FunctionComponent } from 'react';

import { Props } from 'components/content/sections/infoBox/types';

const ContentSectionsInfoBox: FunctionComponent<Props> = ({ title, description, image }) => {
    return (
        <div className="info-box-container">
            <div className="info-box">
                <div className="info-box__title">
                    {title}
                </div>
                <div className="info-box__image">
                    <img
                        src={image}
                        alt="image"
                    />
                </div>
                <div className="info-box__description">
                    {description}
                </div>
            </div>
        </div>
    );
};

export default ContentSectionsInfoBox;

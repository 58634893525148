const translations = {
    title: {
        type: 'text',
        defaultValue: 'Co zyskujesz z REHAB?',
    },
    description: {
        type: 'rich text',
        defaultValue: 'Stworzyliśmy platformę do fizjoterapii REHAB, by pomóc Ci szybko i skutecznie powrócić do pełnej sprawności fizycznej. Precyzyjna analiza danych pacjenta. Ćwiczenia o różnym poziomie zaawansowania. Kamera podążająca za ruchami użytkownika.',
    },
    elements: {
        type: 'array',
        arraySchema: {
            title: { type: 'text' },
            icon: { type: 'image' },
        },
        defaultValue: [{
            title: 'Biblioteka ćwiczeń',
            icon: '/icons/doctor.svg',
        }, {
            title: 'Precyzja',
            icon: '/icons/results.svg',
        }, {
            title: 'Elastyczna pozycja kamery',
            icon: '/icons/legs.svg',
        }],
    },
    customTileTitle: {
        type: 'text',
        defaultValue: 'Zobacz więcej',
    },
    customTileSubTitle: {
        type: 'text',
        defaultValue: '+15',
    },
    button: {
        type: 'text',
        defaultValue: 'Dowiedz się więcej',
    },
};

export default translations;

import { useMemo } from 'react';
import { NextPage } from 'next';

import { PageSection } from 'types/pages';

import Head from 'components/layout/Head';
import PageContainer from 'components/layout/PageContainer';
import SectionsWrapper from 'components/layout/SectionsWrapper';

import SectionDetails from './sections/Details';
import SectionExploreService from './sections/ExploreService';
import SectionHero from './sections/Hero';
import SectionInfoBoxes from './sections/InfoBoxes';
import SectionLearnMore from './sections/LearnMore';
import StyledComponent from './styles';
import { Props } from './types';

const PageHome: NextPage<Props> = ({ localTranslations, metaTranslations }) => {
    const sections: PageSection[] = useMemo(() => [{
        slug: 'hero',
        order: 1,
        isVisible: true,
        children: (slug, translations) => (
            <SectionHero
                key={slug}
                translations={translations}
            />
        ),
    }, {
        slug: 'exploreService',
        order: 2,
        isVisible: true,
        children: (slug, translations) => (
            <SectionExploreService
                key={slug}
                translations={translations}
            />
        ),
    }, {
        slug: 'details',
        order: 3,
        isVisible: true,
        children: (slug, translations) => (
            <SectionDetails
                key={slug}
                translations={translations}
            />
        ),
    }, {
        slug: 'infoBoxes',
        order: 4,
        isVisible: true,
        children: (slug, translations) => (
            <SectionInfoBoxes
                key={slug}
                translations={translations}
            />
        ),
    }, {
        slug: 'learnMore',
        order: 5,
        isVisible: true,
        children: (slug, translations) => (
            <SectionLearnMore
                key={slug}
                translations={translations}
            />
        ),
    }], []);

    return (
        <StyledComponent className="page-home">
            <Head
                {...metaTranslations}
            />
            <PageContainer>
                <SectionsWrapper
                    sections={sections}
                    localTranslations={localTranslations}
                />
            </PageContainer>
        </StyledComponent>
    );
};

export default PageHome;

import { FunctionComponent } from 'react';

import InfoBox from 'components/content/sections/infoBox';
import LayoutContainer from 'components/layout/LayoutContainer';

import StyledComponent from './styles';
import { Props } from './types';

const PageHomeSectionInfoBoxes: FunctionComponent<Props> = ({ translations }) => {
    const boxes = translations?.elements;

    return (
        <StyledComponent
            className="page-home-section-info-boxes"
            id="infoBoxes"
        >
            <LayoutContainer>
                <div className="info-box-container">
                    {boxes && boxes.map((box, index) => (
                        <InfoBox
                            key={`${box.title}-${index}`}
                            title={box.title}
                            description={box.description}
                            image={box.image}
                        />
                    ))}
                </div>
            </LayoutContainer>
        </StyledComponent>
    );
};

export default PageHomeSectionInfoBoxes;

import { FunctionComponent } from 'react';
import Slider from 'react-slick';
import classnames from 'classnames';

import StyledComponent from './styles';
import { Props } from './types';

const LayoutSlick: FunctionComponent<Props> = ({ children, sliderProps, customClass }) => {
    return (
        <StyledComponent className={(classnames('layout-slick', customClass))}>
            <Slider
                {...sliderProps}
                lazyLoad="ondemand"
            >
                {children}
            </Slider>
        </StyledComponent>
    );
};

export default LayoutSlick;

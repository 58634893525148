import metadata from './meta/metadata';
import details from './sections/details';
import exploreService from './sections/exploreService';
import hero from './sections/hero';
import infoBoxes from './sections/infoBoxes';
import learnMore from './sections/learnMore';

const translations = {
    hero,
    exploreService,
    details,
    infoBoxes,
    learnMore,
};

export const meta = {
    ...metadata,
};

export default translations;

import { FunctionComponent } from 'react';

import LearnMore from 'components/content/sections/learnMore';
import LayoutContainer from 'components/layout/LayoutContainer';

import StyledComponent from './styles';
import { Props } from './types';

const PageHomeSectionLearnMore: FunctionComponent<Props> = ({ translations }) => {
    return (
        <StyledComponent
            className="page-home-section-learn-more"
            id="contact"
        >
            <LayoutContainer>
                <div className="info-box-container">
                    <LearnMore {...translations} />
                </div>
            </LayoutContainer>
        </StyledComponent>
    );
};

export default PageHomeSectionLearnMore;

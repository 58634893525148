const translations = {
    title: {
        type: 'text',
        defaultValue: 'Twój osobisty fizjoterapeuta',
    },
    subTitle: {
        type: 'text',
        defaultValue: 'Analiza ruchu człowieka nr 1 na rynku',
    },
    description: {
        type: 'richText',
        defaultValue: '<b>Rehab</b> bez wysiłku digitalizuje ludzkie ruchy za pomocą naszego oprogramowania do widzenia komputerowego.\n' +
            'Zapewnia najwyższą personalizację użytkownikom dzięki precyzyjnej analizie ruchu i informacjom zwrotnym w czasie rzeczywistym.',
    },
    button: {
        type: 'text',
        defaultValue: 'Kontakt',
    },
};

export default translations;

import { FunctionComponent } from 'react';
import { useRouter } from 'next/router';

import Button from 'components/layout/Button';
import Header from 'components/layout/Header';
import Image from 'components/layout/Image';
import LayoutContainer from 'components/layout/LayoutContainer';

import StyledComponent from './styles';
import { Props } from './types';

const ContentSectionsHero: FunctionComponent<Props> = ({ title, description, buttonContent }) => {
    const router = useRouter();

    return (
        <StyledComponent className="content-section-hero">
            <LayoutContainer className="hero-wrapper">
                <div className="bg-effect bg-effect__left">
                    <img
                        loading="lazy"
                        className="gradient-image"
                        src="/images/hero/right-gradient.svg"
                        alt="gradient"
                    />
                </div>
                <Header
                    className="header"
                    title={title}
                    description={description}
                    isPrimaryTitle={true}
                >
                    <Button
                        onClick={() => router.push('/contact-form')}
                        className="header-button"
                    >
                        {buttonContent}
                    </Button>
                </Header>
                <div className="image-container">
                    <div className="left-image">
                        <Image
                            width="100%"
                            height="100%"
                            objectFit="contain"
                            src="/images/hero/left-molecules.png"
                        />
                    </div>
                </div>
            </LayoutContainer>
            <div className="right-image">
                <Image
                    width="100%"
                    height="100%"
                    objectFit="contain"
                    src="/images/hero/right-molecules.png"
                />
            </div>
            <div className="bg-effect bg-effect__right">
                <img
                    loading="lazy"
                    className="gradient-image"
                    src="/images/hero/right-gradient.svg"
                    alt="gradient"
                />
            </div>
        </StyledComponent>
    );
};

export default ContentSectionsHero;

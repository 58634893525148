import { FunctionComponent, useRef } from 'react';

import Header from 'components/layout/Header';
import { HeaderStyles, HeaderTextPositions } from 'components/layout/Header/types';
import { Sizes } from 'components/layout/LayoutContainer';
import LayoutContainer from 'components/layout/LayoutContainer/component';
import Slick from 'components/layout/Slick';

import StyledComponent from './styles';
import { Props } from './types';

const imagesArray = [
    '/images/explore-service/slide-1.png',
    '/images/explore-service/slide-1.png',
    '/images/explore-service/slide-1.png',
    '/images/explore-service/slide-1.png',
    '/images/explore-service/slide-1.png',
    '/images/explore-service/slide-1.png',
];

const PageHomeSectionExploreServices: FunctionComponent<Props> = ({ translations }) => {
    const imageSliderRef = useRef(null);

    const settings = {
        className: 'center',
        centerMode: true,
        infinite: true,
        autoplay: true,
        centerPadding: 0,
        slidesToShow: 3,
        speed: 500,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                    centerMode: false,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                },
            },
        ],
    };

    return (
        <StyledComponent
            className="page-home-section-explore-services"
            id="services"
        >
            <LayoutContainer className="explore-services">
                <Header
                    className="explore-services-header"
                    title={translations?.title}
                    subTitle={translations?.subTitle}
                    description={translations?.description}
                    style={HeaderStyles.Secondary}
                    textPosition={HeaderTextPositions.Center}
                />
            </LayoutContainer>
            <LayoutContainer size={Sizes.XLarge}>
                <div
                    ref={imageSliderRef}
                    className="image-slider-wrapper"
                >
                    <Slick
                        sliderProps={settings}
                        customClass="image-slider-custom-class"
                    >
                        {imagesArray && imagesArray.map((image, index) => (
                            <div
                                key={index}
                                className="image-wrapper"
                            >
                                <img
                                    src={image}
                                    alt="image"
                                />
                            </div>
                        ))}
                    </Slick>
                </div>
            </LayoutContainer>
        </StyledComponent>
    );
};

export default PageHomeSectionExploreServices;

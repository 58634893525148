const translations = {
    title: {
        type: 'text',
        defaultValue: 'Rehabilitacja szyta na miarę',
    },
    description: {
        type: 'rich text',
        defaultValue: 'REHAB to innowacyjna platforma do fizjoterapii, umożliwiająca użytkownikom samodzielną pracę nad poprawą stanu zdrowia i zwiększeniem sprawności fizycznej.',
    },
    elements: {
        type: 'array',
        arraySchema: {
            title: { type: 'text' },
            description: { type: 'text' },
            image: { type: 'image' },
        },
        defaultValue: [{
            title: 'Indywidualny plan rehabilitacji',
            description: 'Aplikacja REHAB przeprowadzi szczegółową analizę danych i stworzy dla Ciebie spersonalizowany plan rehabilitacyjny, uwzględniając historię urazów czy cele terapeutyczne.',
            image: '/images/details/first-image.png',
        }, {
            title: 'Wizualne wskazówki i materiały interaktywne',
            description: 'Dzięki bibliotece multimediów, zawierającej przejrzyste instrukcje, interaktywne materiały wideo i animacje, aplikacja REHAB ułatwia precyzyjne wykonywanie zalecanych ćwiczeń.',
            image: '/images/details/second-image.png',
        }, {
            title: 'Personalizacja fizjoterapii',
            description: 'Platforma REHAB wykorzystuje dane dotyczące postępów i reakcji w celu dynamicznego dopasowywania programu rehabilitacji do bieżących potrzeb użytkownika.',
            image: '/images/details/third-image.png',
        }],
    },
};

export default translations;

import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.section`
    position: relative;
    width: 100%;
    padding: 5em 0;
    overflow: hidden;
    background: rgb(20,55,87);
    background: radial-gradient(circle, rgba(20,55,87,1) 0%, rgba(22,35,62,1) 100%);

    .learn-more-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        max-width: 720px;
        margin: auto;
        color: ${vars.colorTextWhite};

        .learn-more {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            gap: 1em;

            &__title {
                font-weight: 700;
                font-size: 2.6em;
                line-height: 1.2em;
            }

            &__description {
                font-size: 1.2em;
                line-height: 1.8em;
            }

            &__button {
                margin-top: 1em;

                .button {
                    .button-content {
                        font-weight: 400;
                    }
                }
            }
        }
    }

    @media all and (max-width: ${vars.mobileL}) {
    }
`;

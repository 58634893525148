import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.section`
    width: 100%;
    background-color: ${vars.colorPrimaryBlue};

    .images-with-details {
        display: flex;
        flex-flow: column wrap;
        gap: 6em;
    }

    .details {
        display: flex;
        gap: 2em;
        padding: 4em 0;

        &__element {
            display: flex;
            flex-direction: column;
            gap: 2em;
            align-items: center;
            padding: 0 1em;
            flex: 1 1 100%;
            color: ${vars.colorTextWhite};

            &__title {
                font-weight: 700;
                font-size: 1.2em;
            }

            &__image {
                max-width: 160px;
            }

            &__description {
                max-width: 300px;
                margin: auto;
                text-align: center;
            }
        }
    }

    @media all and (max-width: ${vars.mobileL}) {
        .details {
            gap: 6em;
            flex-wrap: wrap;
        }
    }
`;

import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.section`
    position: relative;
    width: 100%;
    min-height: 30em;
    overflow: hidden;
    padding: 4em 0;

    .details-header {
        max-width: 800px;
        margin: 4em auto;
    }

    @media all and (max-width: ${vars.mobileL}) {
        padding: 2em 0 4em;

        .details-header {
            margin: 2em auto 4em;
        }
    }
`;

import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.section`
    position: relative;
    width: 100%;
    height: 100%;

    .bg-effect {
        position: absolute;
        filter: blur(20px);
        width: 100%;
        height: 100%;

        .gradient-image {
            width: 100%;
            height: 100%;
        }

        &__left {
            left: -50%;
            top: -50%;
        }

        &__right {
            right: -55%;
            top: -5em;
        }
    }

    .hero-wrapper {
        position: relative;
        min-height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .header {
            position: relative;
            left: 0;
            top: 50%;
            max-width: 900px;
            margin: auto;
            z-index: 3;
            text-align: center;

            .header-button {
                justify-content: center;
                align-items: center;
                width: 100%;
                margin-top: 4em;

                .button {
                    padding: 0.8em 3em;

                    .button-content {
                        font-size: 1.2em;
                        font-weight: 500;
                    }
                }
            }
        }

        .image-container {
            position: absolute;
            inset: 0;
            pointer-events: none;

            .left-image {
                left: -16em;
                bottom: -8em;
                position: absolute;
                width: calc(800px * 0.8);
                height: calc(580px * 0.8);
            }
        }
    }

    .right-image {
        right: 0;
        top: 0;
        position: absolute;
        width: 480px;
        height: 1100px;
        pointer-events: none;
    }

    @media all and (max-width: ${vars.desktopS}) {
        .hero-wrapper {
            .image-container {
                .left-image {
                    width: calc(800px * 0.6);
                    height: calc(580px * 0.6);
                    left: -8em;
                }
            }
        }

        .right-image {
            right: 0;
            top: 10em;
            width: 300px;
            height: 600px;
        }
    }

    @media all and (max-width: ${vars.tabletS}) {
        max-height: 100vh;

        .hero-wrapper {
            .image-container {
                .left-image {
                    display: none;
                }
            }
        }

        .right-image {
            display: none;
        }
    }
`;

import { FunctionComponent } from 'react';

import LayoutContainer from 'components/layout/LayoutContainer';

import StyledComponent from './styles';
import { Props } from './types';

const ContentSectionsDetails: FunctionComponent<Props> = ({ elements }) => (
    <StyledComponent className="content-section-details">
        <LayoutContainer className="details">
            {elements?.map((element, index) => (
                <div
                    key={`${element.title}-${index}`}
                    className="details__element"
                >
                    <div className="details__element__title">{element?.title}</div>
                    <div className="details__element__image">
                        <img
                            src={element.image}
                            alt="image"
                        />
                    </div>
                    <div className="details__element__description">{element?.description}</div>
                </div>
            ))}
        </LayoutContainer>
    </StyledComponent>
);

export default ContentSectionsDetails;

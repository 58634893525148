const translations = {
    title: {
        type: 'text',
        defaultValue: 'REHAB - Twój osobisty asystent w procesie fizjoterapii',
    },
    description: {
        type: 'rich text',
        defaultValue: 'Dzięki najnowocześniejszej technologii opartej na widzeniu komputerowym i analizie ruchu użytkownika, aplikacja REHAB skutecznie wspiera pacjentów w powrocie do pełnej sprawności fizycznej.',
    },
    buttonContent: {
        type: 'text',
        defaultValue: 'Dowiedz się więcej',
    },
};

export default translations;

import Component from './component';

export interface Props {
    title: string;
    titleSuffix?: string;
    children?: any;
    description?: string;
    canonical?: string;
    image?: string;
    robots?: string;
}

Component.defaultProps = {
    title: '',
    titleSuffix: ' - Rehab',
    children: null,
    description: null,
    image: null,
};

export default Component;
import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.section`
    position: relative;
    width: 100%;
    min-height: 30em;
    padding: 8em 0;
    overflow: hidden;

    .info-box-container {
        display: flex;
        gap: 4em 3em;
        flex-wrap: wrap;
        justify-content: space-around;
        max-width: 800px;
        margin: auto;

        .info-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            flex: 1 1 260px;
            max-width: 260px;
            gap: 1.5em;

            &__title {
                font-weight: 700;
                font-size: 1.2em;
            }

            &__description {
                line-height: 1.8em;
            }
        }
    }

    @media all and (max-width: ${vars.mobileL}) {
        padding: 4em 0;
    }
`;
